<template>
  <div class="flex-container non-scrollable">
    <div class="scrollable">
      <form @submit.prevent="handleSubmit" class="p-fluid p-5">
        <span class="page-sub-title">TVA :</span>
        <div class="flex mt-3 mb-3 align-items-center" style="gap: 0.5rem;">
          <label for="tvaRecuperable">TVA récupérable</label>
          <Checkbox :binary="true" id="tvaRecuperable" type="text" v-model="tvaRecuperable" />
        </div>
        <span class="page-sub-title">Notes de frais :</span>
        <div class="w-8 card mt-3">
          <p class="font-bold underline">Types de dépense :</p>
          <DataTable
              ref="dt" :value="categoriesNdf" v-model:selection="selectedCategoriesNdf" dataKey="id"
              responsiveLayout="scroll" :scrollable="true" scrollHeight="400px"
          >
            <Column selectionMode="multiple" :exportable="false"></Column>
            <Column field="label" header="Libellé" :sortable="true">
              <template #body="slotProps">
                <InputText v-model="slotProps.data.label" placeholder="Libellé" />
              </template>
            </Column>
            <Column field="maxAmount" header="Montant total maximum sur un an" :sortable="true">
              <template #body="slotProps">
                <InputNumber v-model="slotProps.data.maxAmount" class="w-full" input-class="w-full"
                             mode="decimal" :minFractionDigits="2" :maxFractionDigits="2"
                             suffix=" €" placeholder="Forfait" highlightOnFocus/>
              </template>
            </Column>
            <Column field="info" header="Informations supplémentaires">
              <template #body="slotProps">
                <InputText v-model="slotProps.data.info" placeholder="Informations" />
              </template>
            </Column>
            <Column field="enabled" header="Activé" :sortable="true">
              <template #body="{data}">
                <Checkbox binary v-model="data.enabled"></Checkbox>
              </template>
            </Column>
            <Column field="restaurantTicketsDeduction" header="Déduire des tickets restaurants" :sortable="true">
              <template #body="{data}">
                <Checkbox binary v-model="data.restaurantTicketsDeduction"></Checkbox>
              </template>
            </Column>

            <Column :exportable="false">
              <template #body="slotProps">
                <!--              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />-->
                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteCategoryNdf(slotProps.data)" />
              </template>
            </Column>
          </DataTable>

          <Toolbar class="mt-2 p-2">
            <template #start>
              <Button label="Ajouter une ligne" icon="pi pi-plus" class="p-button-success mr-2 w-full" @click="addCategoryNdf" />
              <Button label="Supprimer" icon="pi pi-trash" class="p-button-danger" @click="deleteSelectedCategoriesNdf" :disabled="!selectedCategoriesNdf || !selectedCategoriesNdf.length" />
            </template>
          </Toolbar>
        </div>
        <span class="page-sub-title">Dispositifs sociaux :</span>
        <div class="w-8 card mt-3">
          <p class="font-bold underline">Types de dépense :</p>
          <DataTable
              ref="dt" :value="categoriesDs" v-model:selection="selectedCategoriesDs" dataKey="id"
              responsiveLayout="scroll" :scrollable="true" scrollHeight="400px"
          >
            <Column selectionMode="multiple" :exportable="false"></Column>
            <Column field="label" header="Libellé" :sortable="true">
              <template #body="slotProps">
                <InputText v-model="slotProps.data.label" placeholder="Libellé" />
              </template>
            </Column>
            <Column field="maxAmount" header="Montant total maximum sur un an" :sortable="true">
              <template #body="slotProps">
                <InputNumber v-model="slotProps.data.maxAmount" class="w-full" input-class="w-full"
                             mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" suffix=" €"
                             placeholder="Forfait" highlightOnFocus/>
              </template>
            </Column>
            <Column field="enabled" header="Activé" :sortable="true">
              <template #body="{data}">
                <Checkbox binary v-model="data.enabled"></Checkbox>
              </template>
            </Column>
            <Column field="regroupOnExport" header="Regrouper lors de l'export virement" :sortable="true">
              <template #body="{data}">
                <Checkbox binary v-model="data.regroupOnExport"></Checkbox>
              </template>
            </Column>
            <Column field="info" header="Informations supplémentaires">
              <template #body="slotProps">
                <InputText v-model="slotProps.data.info" placeholder="Informations" />
              </template>
            </Column>

            <Column :exportable="false">
              <template #body="slotProps">
                <!--              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />-->
                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteCategoryDs(slotProps.data)" />
              </template>
            </Column>
          </DataTable>

          <Toolbar class="mt-2 p-2">
            <template #start>
              <Button label="Ajouter une ligne" icon="pi pi-plus" class="p-button-success mr-2 w-full" @click="addCategoryDs" />
              <Button label="Supprimer" icon="pi pi-trash" class="p-button-danger" @click="deleteSelectedCategoriesDs" :disabled="!selectedCategoriesDs || !selectedCategoriesDs.length" />
            </template>
          </Toolbar>
        </div>

        <div class="page-sub-title pt-5 pb-3">{{ $t('admin.params.commercial.ikScaleTitle') }}</div>

        <div class="flex align-items-center mb-3">
          <label for="majorationElectrique" class="mr-3">{{ $t('admin.params.commercial.ikMajorationElectrique') }}</label>
          <InputNumber id="majorationElectrique" v-model="commercialIkMajorationElectrique" style="width: 70px;"
                       :input-style="{width: '30%'}" :min="0" locale="fr-FR" suffix="%" highlightOnFocus/>
        </div>

        <div class="card w-8">
          <p class="font-bold underline">Voiture :</p>

          <DataTable :value="commercialIkCarsValues" showGridlines>
            <ColumnGroup type="header">
              <Row>
                <Column>
                  <template #header>
                    <div class="flex flex-column">
                      <p class="text-lg mb-0">Nombre de Chevaux</p>
                      <p class="text-sm">*distance</p>
                    </div>
                  </template>
                </Column>
                <Column class="centered-header">
                  <template #header>
                    <div class="flex flex-column text-center">
                      <p class="text-lg mb-0">Jusqu'à 5 000 km</p>
                      <p class="text-sm">n x d*</p>
                    </div>
                  </template>
                </Column>
                <Column class="centered-header" colspan="2">
                  <template #header>
                    <div class="flex flex-column text-center">
                      <p class="text-lg mb-0">5 001 km à 20 000 km</p>
                      <p class="text-sm">(n x d*) + n1</p>
                    </div>
                  </template>
                </Column>
                <Column class="centered-header">
                  <template #header>
                    <div class="flex flex-column text-center">
                      <p class="text-lg mb-0">Plus de 20 001 km</p>
                      <p class="text-sm">n x d*</p>
                    </div>
                  </template>
                </Column>
              </Row>
              <Row>
                <Column header="" field="horsePower" />
                <Column header="n" class="centered-header" field="untilFiveThousandCoefficientN" />
                <Column header="n" class="centered-header" field="fiveThousandUntilTwentyThousandCoefficientN" />
                <Column header="n1" class="centered-header" field="fiveThousandUntilTwentyThousandCoefficientNPlusOne" />
                <Column header="n" class="centered-header" field="moreThanTwentyThousandCoefficientN" />
              </Row>
            </ColumnGroup>
            <Column field="horsePower">
              <template #body="slotProps">
                {{ slotProps.data.horsePower }}
              </template>
            </Column>
            <Column field="untilFiveThousandCoefficientN">
              <template #body="slotProps">
                <InputNumber
                    v-model="slotProps.data.untilFiveThousandCoefficientN"
                    placeholder="Define a value"
                    mode="decimal" highlightOnFocus
                    input-style="text-align: center;"
                    :minFractionDigits="2"
                    :maxFractionDigits="5"
                />
              </template>
            </Column>
            <Column field="fiveThousandUntilTwentyThousandCoefficientN" >
              <template #body="slotProps">
                <InputNumber
                    v-model="slotProps.data.fiveThousandUntilTwentyThousandCoefficientN"
                    placeholder="Define a value"
                    mode="decimal" highlightOnFocus
                    input-style="text-align: center;"
                    :minFractionDigits="2"
                    :maxFractionDigits="5"
                />
              </template>
            </Column>
            <Column field="fiveThousandUntilTwentyThousandCoefficientNPlusOne">
              <template #body="slotProps">
                <InputNumber
                    v-model="slotProps.data.fiveThousandUntilTwentyThousandCoefficientNPlusOne"
                    input-style="text-align: center;" highlightOnFocus
                    placeholder="Define a value"
                />
              </template>
            </Column>
            <Column field="moreThanTwentyThousandCoefficientN">
              <template #body="slotProps">
                <InputNumber
                    v-model="slotProps.data.moreThanTwentyThousandCoefficientN"
                    placeholder="Define a value"
                    mode="decimal" highlightOnFocus
                    input-style="text-align: center;"
                    :minFractionDigits="2"
                    :maxFractionDigits="5"
                />
              </template>
            </Column>
            <ColumnGroup type="footer">
              <Row>
                <Column field="horsePower">
                  <template #footer>
                    Bareme automatique
                  </template>
                </Column>
                <Column field="untilFiveThousandCoefficientN" class="checkboxFooter">
                  <template #footer>
                    <Checkbox v-model="commercialIkCarFirstCol" :binary="true" @input="firstCarColClicked" />
                  </template>
                </Column>
                <Column field="fiveThousandUntilTwentyThousandCoefficientN" colspan="2" class="checkboxFooter">
                  <template #footer>
                    <Checkbox v-model="commercialIkCarSecondCol" :binary="true" @input="secondCarColClicked"/>
                  </template>
                </Column>
                <Column field="moreThanTwentyThousandCoefficientN" class="checkboxFooter">
                  <template #footer>
                    <Checkbox v-model="commercialIkCarThirdCol" :binary="true" @input="thirdCarColClicked"/>
                  </template>
                </Column>
              </Row>
            </ColumnGroup>
          </DataTable>
        </div>

        <div class="card mt-4 w-8">
          <p class="font-bold underline">Moto :</p>
          <DataTable :value="commercialIkMotorbikesValues" show-gridlines>
            <ColumnGroup type="header">
              <Row>
                <Column>
                  <template #header>
                    <div class="flex flex-column">
                      <p class="text-lg mb-0">Nombre de Chevaux</p>
                      <p class="text-sm">*distance</p>
                    </div>
                  </template>
                </Column>
                <Column class="centered-header">
                  <template #header>
                    <div class="flex flex-column text-center">
                      <p class="text-lg mb-0">Jusqu'à 5 000 km</p>
                      <p class="text-sm">n x d*</p>
                    </div>
                  </template>
                </Column>
                <Column class="centered-header" colspan="2">
                  <template #header>
                    <div class="flex flex-column text-center">
                      <p class="text-lg mb-0">5 001 km à 20 000 km</p>
                      <p class="text-sm">(n x d*) + n1</p>
                    </div>
                  </template>
                </Column>
                <Column class="centered-header">
                  <template #header>
                    <div class="flex flex-column text-center">
                      <p class="text-lg mb-0">Plus de 20 001 km</p>
                      <p class="text-sm">n x d*</p>
                    </div>
                  </template>
                </Column>
              </Row>
              <Row>
                <Column header="" field="horsePower" />
                <Column header="n" class="centered-header" field="untilFiveThousandCoefficientN" />
                <Column header="n" class="centered-header" field="fiveThousandUntilTwentyThousandCoefficientN" />
                <Column header="n1" class="centered-header" field="fiveThousandUntilTwentyThousandCoefficientNPlusOne" />
                <Column header="n" class="centered-header" field="moreThanTwentyThousandCoefficientN" />
              </Row>
            </ColumnGroup>
            <Column field="horsePower">
              <template #body="slotProps">
                {{ slotProps.data.horsePower }}
              </template>
            </Column>
            <Column field="untilFiveThousandCoefficientN">
              <template #body="slotProps">
                <InputNumber
                    v-model="slotProps.data.untilFiveThousandCoefficientN"
                    placeholder="Define a value"
                    mode="decimal" highlightOnFocus
                    input-style="text-align: center;"
                    :minFractionDigits="2"
                    :maxFractionDigits="5"
                />
              </template>
            </Column>
            <Column field="fiveThousandUntilTwentyThousandCoefficientN">
              <template #body="slotProps">
                <InputNumber
                    v-model="slotProps.data.fiveThousandUntilTwentyThousandCoefficientN"
                    placeholder="Define a value"
                    mode="decimal" highlightOnFocus
                    input-style="text-align: center;"
                    :minFractionDigits="2"
                    :maxFractionDigits="5"
                />
              </template>
            </Column>
            <Column field="fiveThousandUntilTwentyThousandCoefficientNPlusOne">
              <template #body="slotProps">
                <InputNumber
                    v-model="slotProps.data.fiveThousandUntilTwentyThousandCoefficientNPlusOne"
                    placeholder="Define a value" highlightOnFocus
                    input-style="text-align: center;"
                />
              </template>
            </Column>
            <Column field="moreThanTwentyThousandCoefficientN">
              <template #body="slotProps">
                <InputNumber
                    v-model="slotProps.data.moreThanTwentyThousandCoefficientN"
                    placeholder="Define a value"
                    mode="decimal" highlightOnFocus
                    input-style="text-align: center;"
                    :minFractionDigits="2"
                    :maxFractionDigits="5"
                />
              </template>
            </Column>
            <ColumnGroup type="footer">
              <Row>
                <Column field="horsePower">
                  <template #footer>
                    Bareme automatique
                  </template>
                </Column>
                <Column field="untilFiveThousandCoefficientN" class="checkboxFooter">
                  <template #footer>
                    <Checkbox v-model="commercialIkMotorbikeFirstCol" :binary="true" @input="firstMotorbikeColClicked" />
                  </template>
                </Column>
                <Column field="fiveThousandUntilTwentyThousandCoefficientN" colspan="2" class="checkboxFooter">
                  <template #footer>
                    <Checkbox v-model="commercialIkMotorbikeSecondCol" :binary="true" @input="secondMotorbikeColClicked"/>
                  </template>
                </Column>
                <Column field="moreThanTwentyThousandCoefficientN" class="checkboxFooter">
                  <template #footer>
                    <Checkbox v-model="commercialIkMotorbikeThirdCol" :binary="true" @input="thirdMotorbikeColClicked"/>
                  </template>
                </Column>
              </Row>
            </ColumnGroup>
          </DataTable>
        </div>

        <div class="card mt-4 w-8">
          <p class="font-bold underline">Cylomoteur :</p>
          <DataTable :value="commercialIkMotorcyclesValues" show-gridlines>
            <ColumnGroup type="header">
              <Row>
                <Column>
                  <template #header>
                    <div class="flex flex-column">
                      <p class="text-lg mb-0"></p>
                      <p class="text-sm">*distance</p>
                    </div>
                  </template>
                </Column>
                <Column class="centered-header">
                  <template #header>
                    <div class="flex flex-column text-center">
                      <p class="text-lg mb-0">Jusqu'à 3 000 km</p>
                      <p class="text-sm">n x d*</p>
                    </div>
                  </template>
                </Column>
                <Column class="centered-header" colspan="2">
                  <template #header>
                    <div class="flex flex-column text-center">
                      <p class="text-lg mb-0">3 001 km à 6 000 km</p>
                      <p class="text-sm">(n x d*) + n1</p>
                    </div>
                  </template>
                </Column>
                <Column class="centered-header">
                  <template #header>
                    <div class="flex flex-column text-center">
                      <p class="text-lg mb-0">Plus de 6 001 km</p>
                      <p class="text-sm">n x d*</p>
                    </div>
                  </template>
                </Column>
              </Row>
              <Row>
                <Column header="" field="horsePower" />
                <Column header="n" class="centered-header" field="untilFiveThousandCoefficientN" />
                <Column header="n" class="centered-header" field="fiveThousandUntilTwentyThousandCoefficientN" />
                <Column header="n1" class="centered-header" field="fiveThousandUntilTwentyThousandCoefficientNPlusOne" />
                <Column header="n" class="centered-header" field="moreThanTwentyThousandCoefficientN" />
              </Row>
            </ColumnGroup>
            <Column field="horsePower">
              <template #body="slotProps">
                {{ slotProps.data.horsePower }}
              </template>
            </Column>
            <Column field="untilFiveThousandCoefficientN">
              <template #body="slotProps">
                <InputNumber
                    v-model="slotProps.data.firstCol"
                    placeholder="Define a value"
                    mode="decimal" highlightOnFocus
                    input-style="text-align: center;"
                    :minFractionDigits="2"
                    :maxFractionDigits="5"
                />
              </template>
            </Column>
            <Column field="fiveThousandUntilTwentyThousandCoefficientN">
              <template #body="slotProps">
                <InputNumber
                    v-model="slotProps.data.secondCol"
                    placeholder="Define a value"
                    mode="decimal" highlightOnFocus
                    input-style="text-align: center;"
                    :minFractionDigits="2"
                    :maxFractionDigits="5"
                />
              </template>
            </Column>
            <Column field="fiveThousandUntilTwentyThousandCoefficientNPlusOne">
              <template #body="slotProps">
                <InputNumber
                    v-model="slotProps.data.thirdCol"
                    placeholder="Define a value" highlightOnFocus
                    input-style="text-align: center;"
                />
              </template>
            </Column>
            <Column field="moreThanTwentyThousandCoefficientN">
              <template #body="slotProps">
                <InputNumber
                    v-model="slotProps.data.fourthCol"
                    placeholder="Define a value"
                    mode="decimal" highlightOnFocus
                    input-style="text-align: center;"
                    :minFractionDigits="2"
                    :maxFractionDigits="5"
                />
              </template>
            </Column>
            <ColumnGroup type="footer">
              <Row>
                <Column field="horsePower">
                  <template #footer>
                    Bareme automatique
                  </template>
                </Column>
                <Column field="untilFiveThousandCoefficientN" class="checkboxFooter">
                  <template #footer>
                    <Checkbox v-model="commercialIkMotorcycleFirstCol" :binary="true" @input="firstMotorcycleColClicked" />
                  </template>
                </Column>
                <Column field="fiveThousandUntilTwentyThousandCoefficientN" colspan="2" class="checkboxFooter">
                  <template #footer>
                    <Checkbox v-model="commercialIkMotorcycleSecondCol" :binary="true" @input="secondMotorcycleColClicked"/>
                  </template>
                </Column>
                <Column field="moreThanTwentyThousandCoefficientN" class="checkboxFooter">
                  <template #footer>
                    <Checkbox v-model="commercialIkMotorcycleThirdCol" :binary="true" @input="thirdMotorcycleColClicked"/>
                  </template>
                </Column>
              </Row>
            </ColumnGroup>
          </DataTable>
        </div>
        <div v-if="hasAccess($route.name, true)" class="float-button">
          <Button :label="$t('admin.params.company.registerButton')" type="submit" class="w-full p-3"></Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {disablePComponentsOfCurrentPage, generateId, hasAccess} from "@/utils/Misc";

export default {
  name: "Ndf",
  data () {
    return {
      hasAccess: hasAccess,
      submitted: false,
      selectedCategoriesNdf: [],
      categoriesNdf: [],
      categoriesDs: [],
      selectedCategoriesDs: [],
      tvaRecuperable: false,

      commercialIkCarFirstCol: false,
      commercialIkCarSecondCol: false,
      commercialIkCarThirdCol: false,

      commercialIkCarsValues: [
        { "horsePower": "3 cv et moins", "untilFiveThousandCoefficientN": 0.502, "fiveThousandUntilTwentyThousandCoefficientN": 0.3, "fiveThousandUntilTwentyThousandCoefficientNPlusOne": 1007, "moreThanTwentyThousandCoefficientN": 0.35 },
        { "horsePower": "4 cv", "untilFiveThousandCoefficientN": 0.575, "fiveThousandUntilTwentyThousandCoefficientN": 0.323, "fiveThousandUntilTwentyThousandCoefficientNPlusOne": 1262, "moreThanTwentyThousandCoefficientN": 0.387 },
        { "horsePower": "5 cv", "untilFiveThousandCoefficientN": 0.603, "fiveThousandUntilTwentyThousandCoefficientN": 0.339, "fiveThousandUntilTwentyThousandCoefficientNPlusOne": 1382, "moreThanTwentyThousandCoefficientN": 0.405 },
        { "horsePower": "6 cv", "untilFiveThousandCoefficientN": 0.631, "fiveThousandUntilTwentyThousandCoefficientN": 0.355, "fiveThousandUntilTwentyThousandCoefficientNPlusOne": 1382, "moreThanTwentyThousandCoefficientN": 0.425 },
        { "horsePower": "7 cv et plus", "untilFiveThousandCoefficientN": 0.661, "fiveThousandUntilTwentyThousandCoefficientN": 0.374, "fiveThousandUntilTwentyThousandCoefficientNPlusOne": 1435, "moreThanTwentyThousandCoefficientN": 0.446 },
      ],

      commercialIkMotorbikeFirstCol: false,
      commercialIkMotorbikeSecondCol: false,
      commercialIkMotorbikeThirdCol: false,

      commercialIkMotorbikesValues: [
        { "horsePower": "1 ou 2 cv", "untilFiveThousandCoefficientN": 0.375, "fiveThousandUntilTwentyThousandCoefficientN": 0.094, "fiveThousandUntilTwentyThousandCoefficientNPlusOne": 845, "moreThanTwentyThousandCoefficientN": 0.234 },
        { "horsePower": "De 3 à 5 cv", "untilFiveThousandCoefficientN": 0.444, "fiveThousandUntilTwentyThousandCoefficientN": 0.078, "fiveThousandUntilTwentyThousandCoefficientNPlusOne": 1099, "moreThanTwentyThousandCoefficientN": 0.261 },
        { "horsePower": "Plus de 5 cv", "untilFiveThousandCoefficientN": 0.575, "fiveThousandUntilTwentyThousandCoefficientN": 0.075, "fiveThousandUntilTwentyThousandCoefficientNPlusOne": 1502, "moreThanTwentyThousandCoefficientN": 0.325 },
      ],

      commercialIkMotorcycleFirstCol: false,
      commercialIkMotorcycleSecondCol: false,
      commercialIkMotorcycleThirdCol: false,

      commercialIkMotorcyclesValues: [
        { "horsePower": "", "firstCol": 0.299, "secondCol": 0.07, "thirdCol": 458, "fourthCol": 0.162 },
      ],

      commercialIkMajorationElectrique: 20
    }
  },
  async created() {
    // get only company settings ?
    await this.$store.dispatch('misc/getAllSettings', {})
    await this.initializeFields()
  },
  computed: {
    ...mapState({
      settings: state => state.misc.settings,
      ndfSettings: state => state.misc.settings.NDF
    })
  },
  mounted () {
    this.$nextTick(() => {
      if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
    })
  },
  methods: {
    addCategoryNdf () {
      this.categoriesNdf.push({ label: '', maxAmount: null, enabled: false, id: generateId(), restaurantTicketsDeduction: false })
    },
    addCategoryDs () {
      this.categoriesDs.push({ label: '', maxAmount: null, enabled: false, regroupOnExport: false, id: generateId() })
    },
    deleteSelectedCategoriesNdf () {
      this.categoriesNdf = this.categoriesNdf.filter(cndf => !this.selectedCategoriesNdf.includes(cndf))
    },
    deleteSelectedCategoriesDs () {
      this.categoriesDs = this.categoriesDs.filter(cds => !this.selectedCategoriesDs.includes(cds))
    },
    deleteCategoryNdf (cndf) {
      this.categoriesNdf = this.categoriesNdf.filter(c => c !== cndf)
    },
    deleteCategoryDs (cds) {
      this.categoriesDs = this.categoriesDs.filter(c => c !== cds)
    },
    async initializeFields() {
      this.categoriesNdf = JSON.parse(JSON.stringify(this.ndfSettings.categoriesNdf))
      this.categoriesDs = JSON.parse(JSON.stringify(this.ndfSettings.categoriesDs))
      this.tvaRecuperable = this.ndfSettings.tvaRecuperable

      this.commercialIkCarsValues = JSON.parse(JSON.stringify(this.ndfSettings.baremesIK.cars.coefficients))
      this.commercialIkCarFirstCol = this.ndfSettings.baremesIK.cars.default === 1
      this.commercialIkCarSecondCol = this.ndfSettings.baremesIK.cars.default === 2
      this.commercialIkCarThirdCol = this.ndfSettings.baremesIK.cars.default === 3

      this.commercialIkMotorbikesValues = JSON.parse(JSON.stringify(this.ndfSettings.baremesIK.motorbikes.coefficients))
      this.commercialIkMotorbikeFirstCol = this.ndfSettings.baremesIK.motorbikes.default === 1
      this.commercialIkMotorbikeSecondCol = this.ndfSettings.baremesIK.motorbikes.default === 2
      this.commercialIkMotorbikeThirdCol = this.ndfSettings.baremesIK.motorbikes.default === 3

      this.commercialIkMotorcyclesValues = JSON.parse(JSON.stringify(this.ndfSettings.baremesIK.motorcycles.coefficients))
      this.commercialIkMotorcycleFirstCol = this.ndfSettings.baremesIK.motorcycles.default === 1
      this.commercialIkMotorcycleSecondCol = this.ndfSettings.baremesIK.motorcycles.default === 2
      this.commercialIkMotorcycleThirdCol = this.ndfSettings.baremesIK.motorcycles.default === 3

      this.commercialIkMajorationElectrique = this.ndfSettings.baremesIK.majorationElectrique * 100

      if (!(this.commercialIkCarFirstCol || this.commercialIkCarSecondCol || this.commercialIkCarThirdCol)) {
        this.commercialIkCarThirdCol = true
        this.thirdCarColClicked(this.commercialIkCarThirdCol)
      }

      if (!(this.commercialIkMotorbikeFirstCol || this.commercialIkMotorbikeSecondCol || this.commercialIkMotorbikeThirdCol)) {
        this.commercialIkMotorbikeThirdCol = true
        this.thirdMotorbikeColClicked(this.commercialIkMotorbikeThirdCol)
      }

      if (!(this.commercialIkMotorcycleFirstCol || this.commercialIkMotorcycleSecondCol || this.commercialIkMotorcycleThirdCol)) {
        this.commercialIkMotorcycleThirdCol = true
        this.thirdMotorcycleColClicked(this.commercialIkMotorcycleThirdCol)
      }
    },
    async handleSubmit () {
      try {
        this.$store.state.misc.loading = true
        const payload = JSON.parse(JSON.stringify(this.settings))

        payload.NDF.categoriesNdf = this.categoriesNdf.filter(cndf => cndf.label) // && cndf.maxAmount
        payload.NDF.categoriesDs = this.categoriesDs.filter(cds => cds.label) // && cds.maxAmount
        payload.NDF.tvaRecuperable = this.tvaRecuperable

        if (!(this.commercialIkCarFirstCol || this.commercialIkCarSecondCol || this.commercialIkCarThirdCol)) {
          this.commercialIkCarThirdCol = true
          this.thirdCarColClicked(this.commercialIkCarThirdCol)
        }

        if (!(this.commercialIkMotorbikeFirstCol || this.commercialIkMotorbikeSecondCol || this.commercialIkMotorbikeThirdCol)) {
          this.commercialIkMotorbikeThirdCol = true
          this.thirdMotorbikeColClicked(this.commercialIkMotorbikeThirdCol)
        }

        if (!(this.commercialIkMotorcycleFirstCol || this.commercialIkMotorcycleSecondCol || this.commercialIkMotorcycleThirdCol)) {
          this.commercialIkMotorcycleThirdCol = true
          this.thirdMotorcycleColClicked(this.commercialIkMotorcycleThirdCol)
        }

        payload.NDF.baremesIK.majorationElectrique = this.commercialIkMajorationElectrique ? this.commercialIkMajorationElectrique /= 100 : 0

        payload.NDF.baremesIK.cars = {
          default: this.commercialIkCarFirstCol ? 1 : this.commercialIkCarSecondCol ? 2 : 3,
          coefficients: this.commercialIkCarsValues
        }

        payload.NDF.baremesIK.motorbikes = {
          default: this.commercialIkMotorbikeFirstCol ? 1 : this.commercialIkMotorbikeSecondCol ? 2 : 3,
          coefficients: this.commercialIkMotorbikesValues
        }

        payload.NDF.baremesIK.motorcycles = {
          default: this.commercialIkMotorcycleFirstCol ? 1 : this.commercialIkMotorcycleSecondCol ? 2 : 3,
          coefficients: this.commercialIkMotorcyclesValues
        }

        await this.$store.dispatch('misc/updateSettings', payload)
        await this.initializeFields()
        this.$toast.add({severity: 'success', summary: 'Succès', detail: 'Paramètres sauvegardés', life: 3000});
        this.$store.state.misc.loading = false
      } catch (e) {
        this.$toast.add({severity: 'error', summary: 'Erreur', detail: 'Une erreur s\'est produite', life: 3000});
        this.$store.state.misc.loading = false
      }
    },
    firstCarColClicked (value) {
      if (value) {
        this.commercialIkCarSecondCol = false
        this.commercialIkCarThirdCol = false
      }
    },
    secondCarColClicked (value) {
      if (value) {
        this.commercialIkCarSecondCol = false
        this.$confirm.require({
          header: 'Sélection du second barème',
          message: 'La sélection du second barème entraînera un ajustement important lors de la régularisation des IKM.',
          acceptLabel: 'Confirmer',
          rejectLabel: 'Annuler',
          accept: async () => {
            this.commercialIkCarFirstCol = false
            this.commercialIkCarThirdCol = false
            this.commercialIkCarSecondCol = true
            this.$confirm.close()
          },
          reject: () => {
          }
        })
      }
    },
    thirdCarColClicked (value) {
      if (value) {
        this.commercialIkCarSecondCol = false
        this.commercialIkCarFirstCol = false
      }
    },
    firstMotorbikeColClicked (value) {
      if (value) {
        this.commercialIkMotorbikeSecondCol = false
        this.commercialIkMotorbikeThirdCol = false
      }
    },
    secondMotorbikeColClicked (value) {
      if (value) {
        this.commercialIkMotorbikeSecondCol = false
        this.$confirm.require({
          header: 'Sélection du second barème',
          message: 'La sélection du second barème entraînera un ajustement important lors de la régularisation des IKM.',
          acceptLabel: 'Confirmer',
          rejectLabel: 'Annuler',
          accept: async () => {
            this.commercialIkMotorbikeSecondCol = true
            this.commercialIkMotorbikeFirstCol = false
            this.commercialIkMotorbikeThirdCol = false
            this.$confirm.close()
          },
          reject: () => {
          }
        })
      }
    },
    thirdMotorbikeColClicked (value) {
      if (value) {
        this.commercialIkMotorbikeSecondCol = false
        this.commercialIkMotorbikeFirstCol = false
      }
    },
    firstMotorcycleColClicked (value) {
      if (value) {
        this.commercialIkMotorcycleSecondCol = false
        this.commercialIkMotorcycleThirdCol = false
      }
    },
    secondMotorcycleColClicked (value) {
      if (value) {
        this.commercialIkMotorcycleSecondCol = false
        this.$confirm.require({
          header: 'Sélection du second barème',
          message: 'La sélection du second barème entraînera un ajustement important lors de la régularisation des IKM.',
          acceptLabel: 'Confirmer',
          rejectLabel: 'Annuler',
          accept: async () => {
            this.commercialIkMotorcycleFirstCol = false
            this.commercialIkMotorcycleThirdCol = false
            this.commercialIkMotorcycleSecondCol = true
            this.$confirm.close()
          },
          reject: () => {
          }
        })
      }
    },
    thirdMotorcycleColClicked (value) {
      if (value) {
        this.commercialIkMotorcycleSecondCol = false
        this.commercialIkMotorcycleFirstCol = false
      }
    }
  }
}
</script>

<style scoped>

</style>

<style>
.centered-header .p-column-header-content {
  justify-content: center;
}
.checkboxFooter {
  text-align: center !important;
}
</style>
